<template>
  <div class="radar-set">
    <div class="receive">
      <div class="title">
        接收人员范围
      </div>
      <van-radio-group v-model="radio">
        <van-cell-group>
          <van-cell title-class="left-title" label-class="left-label" center  label="接收所有人的触发行为消息" title="所有人"  clickable @click="radio = '0'" >
            <template #right-icon>
              <van-radio name="0" />
            </template>
          </van-cell>
          <van-cell title-class="left-title" label-class="left-label" center  label="仅接收客户的触发行为消息" title="仅客户"  clickable @click="radio = '1'" >
            <template #right-icon>
              <van-radio name="1" />
            </template>
          </van-cell>
          <van-cell title-class="left-title" label-class="left-label" center  label="仅接收重点关注客户的触发行为消息" title="仅重点关注客户"  clickable @click="radio = '2'" >
            <template #right-icon>
              <van-radio name="2" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>
    <div class="action">
      <div class="title">
        触发行为
      </div>
      <van-cell-group>
        <van-cell title-class="left-title" label-class="left-label" center  label="当用户访问了文章、表单、活动等内容时提醒" title="访问" >
          <template #right-icon>
            <van-switch v-model="action.visit" class="radar-switch" />
          </template>
        </van-cell>
        <van-cell title-class="left-title" label-class="left-label" center  label="当用户转发了文章、表单、活动等内容时提醒" title="转发" >
          <template #right-icon>
            <van-switch v-model="action.forward" class="radar-switch" />
          </template>
        </van-cell>
        <van-cell title-class="left-title" label-class="left-label" center  label="当用户留资、提交表单等时提醒" title="留资" >
          <template #right-icon>
            <van-switch v-model="action.leave" class="radar-switch" />
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <div class="ok-btn">
      <van-button block type="info" @click="setRadar">确认</van-button>
    </div>
  </div>
</template>

<script>
import mine from '@/api/mine';
import {Toast} from 'vant';
export default {
  name: 'radarSetting',
  data() {
    return {
      radio: '',
      action: {
        visit: false,
        forward: false,
        leave: false
      }
    };
  },
  methods: {
    async getRadarSet () {
      let params={
        opId: localStorage.getItem('platform_opId'),
        estateId: localStorage.getItem('platform_estateId'),
      };
      let { code, data } = await mine.getRadarSet(params);
      if (code === '0') {
        this.radio=data.receiveScope.toString();
        data.behaviorType.indexOf('1')!==-1 ?this.action.visit=true:this.action.visit=false;
        data.behaviorType.indexOf('2')!==-1 ?this.action.forward=true:this.action.forward=false;
        data.behaviorType.indexOf('3')!==-1 ?this.action.leave=true:this.action.leave=false;
      }
    },
    async setRadar () {
      let behaviorType=[];
      if (this.action.visit)behaviorType.push('1');
      if (this.action.forward)behaviorType.push('2');
      if (this.action.leave)behaviorType.push('3');
      let params={
        opId: localStorage.getItem('platform_opId'),
        estateId: localStorage.getItem('platform_estateId'),
        radarSettingType: this.radio,
        behaviorType: behaviorType.join(',')
      };
      let { code } = await mine.setRadar(params);
      if (code === '0') {
        Toast('设置成功');
      }
    },
  },
  mounted() {
    this.getRadarSet();
  }
};
</script>

<style lang="less" scoped>
.radar-set{
  .title{
    padding: 12px 0px 8px 16px;
    font-size: 12px;
    color: @gray9;
    line-height: 17px;
  }
  .left-label{
    width: 240px;
    font-size: 12px;
    color: @gray9;
    line-height: 17px;
  }
  .left-title{
    font-size: 15px;
    line-height: 21px;
    color: @gray3;
  }
  .radar-switch{
    font-size: 15px;
  }
  .ok-btn{
    padding: 40px 16px 0px 16px;
    background: #F5F7FA;
  }
}
</style>
